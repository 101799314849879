import { isProduction } from "@libry-content/common";
import { isValidFrontendLocale, validateFrontendLocaleOrFallbackToDefault } from "@libry-content/localization";
import * as Sentry from "@sentry/nextjs";
import { IntlConfig } from "next-intl"; // eslint-disable-line no-restricted-imports
// eslint-disable-next-line no-restricted-imports
import { getRequestConfig } from "next-intl/server";
import { notFound } from "next/navigation";

export type IntlMessages = IntlConfig["messages"];

export const missingTranslationEventKey = "missing-translation";
export type MissingTranslationEvent = CustomEvent<{ key: string }>;

export const getMessages = async (locale: any) => {
  const validatedLocale = validateFrontendLocaleOrFallbackToDefault(locale);
  return (await import(`../locales/${validatedLocale}/common.json`)).default as IntlMessages;
};

const onError = (error: any) => {
  if (isProduction) {
    Sentry.captureException(error);
  }
};

const getMessageFallback = ({ key }: any) => {
  const errorMessage = `Missing translation: "${key}"`;

  console.log(`%c 💬 ${errorMessage} `, "background: red; color: white; padding: 5px;");

  // Send event to the MissingTranslationToaster
  const missingTranslationEvent: MissingTranslationEvent = new CustomEvent(missingTranslationEventKey, {
    detail: { key },
  });
  // The recieving component might not be mounted yet, so we delay the event dispatch
  typeof window !== "undefined" && setTimeout(() => window.dispatchEvent(missingTranslationEvent), 500);

  if (isProduction) {
    Sentry.captureException(errorMessage);
  }

  return key;
};

export const commonNextIntlConfig: Pick<IntlConfig, "onError" | "getMessageFallback" | "timeZone"> = {
  onError: onError,
  getMessageFallback,
  timeZone: "Europe/Oslo",
};

export default getRequestConfig(async ({ locale }) => {
  // Validate that the incoming `locale` parameter is valid
  if (!isValidFrontendLocale(locale)) notFound();

  return {
    messages: await getMessages(locale),
    locale,
    ...commonNextIntlConfig,
  };
});

import { getEnabledFeatureToggles } from "@libry-content/common";
import { useMount } from "react-use";
import { libryContentVersion } from "./libryContentVersion";

function logVercelInfo() {
  console.info(`🌐 Libry Content version: ${libryContentVersion}`);
}

const logEnabledFeatureToggles = () => {
  const enabledFeatureToggles = getEnabledFeatureToggles();
  enabledFeatureToggles.length && console.log("🎛️ Enabled feature toggles: ", enabledFeatureToggles);
};

export const useLogDevInfo = () => {
  useMount(() => {
    logVercelInfo();
    logEnabledFeatureToggles();
  });
};
